import Concept from "./Concepts";

function List(props) {
  return (
    <div>
      <Concept
        title={props.concepts[0].title}
        image={props.concepts[0].image}
        description={props.concepts[0].description}
      ></Concept>
      <Concept
        title={props.concepts[1].title}
        image={props.concepts[1].image}
        description={props.concepts[1].description}
      ></Concept>
      <Concept
        title={props.concepts[2].title}
        image={props.concepts[2].image}
        description={props.concepts[2].description}
      ></Concept>
    </div>
  );
}
export default List;
