import keyConceptsImage from "./assets/images/vblogcr-logo-no-background.png";
import componentsImage from "./assets/images/creativity-3D.png";
import stateImage from "./assets/images/megaphone-3d.png";
import eventsImage from "./assets/images/plant-3d.png";
import List from "./Components/List";

const concepts = [
  {
    title: "Creatividad Visual",
    image: componentsImage,
    description:
      "Explora la expresión artística a través de mis videos, fusionando técnicas de grabación y edición para ofrecer una experiencia visual única.",
  },
  {
    title: "Historias Auténticas",
    image: stateImage,
    description:
      "Capturo momentos genuinos que narran mis experiencias y perspectivas, compartiendo historias auténticas que conectan con la audiencia de manera emotiva.",
  },
  {
    title: "Mejora Continua",
    image: eventsImage,
    description:
      "Busco mejorar constantemente mis habilidades en la producción de videos, aprendiendo nuevas técnicas y explorando diferentes estilos para evolucionar como creador de contenido.",
  },
];

function App() {
  return (
    <div>
      <header>
        <img src={keyConceptsImage} alt="vblogcr logo" />
         <p> </p>
        <h1>SITIO WEB EN CONSTRUCCIÓN</h1>
        <p>Explora algunos de los puntos clave de <b>vblogcr</b></p>
      </header>
      <List concepts={concepts}></List>
    </div>
  );
}

export default App;
