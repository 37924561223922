function Concepts(props) {
  return (
    <div id="concepts">
      <div className="concept">
        <img src={props.image} alt={props.title} />
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
  );
}
export default Concepts;
